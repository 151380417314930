@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities 

{
    @layer utilities {
        .backface-hidden {
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
        
        .perspective-\[1500px\] {
          -webkit-perspective: 1500px;
          perspective: 1500px;
        }
        
        .rotate-y-180 {
          transform: rotateY(180deg);
        }
        
        .group:hover .group-hover\:rotate-y-180 {
          transform: rotateY(180deg);
        }
        
        .group:hover .group-hover\:rotate-y-0 {
          transform: rotateY(0deg);
        }
      }
  }

  body {
    background-image: url('./images/pngtree-modern-double-color-futuristic-neon-background-image_351866.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: var(--ff-secondary);
    color: var(--clr-grey-1);
    line-height: 1.5;
    font-size: 0.875rem;
  }